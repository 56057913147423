exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affisch-js": () => import("./../../../src/pages/affisch.js" /* webpackChunkName: "component---src-pages-affisch-js" */),
  "component---src-pages-familjefisketur-js": () => import("./../../../src/pages/familjefisketur.js" /* webpackChunkName: "component---src-pages-familjefisketur-js" */),
  "component---src-pages-host-var-js": () => import("./../../../src/pages/host-var.js" /* webpackChunkName: "component---src-pages-host-var-js" */),
  "component---src-pages-hyra-js": () => import("./../../../src/pages/hyra.js" /* webpackChunkName: "component---src-pages-hyra-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-marmor-js": () => import("./../../../src/pages/marmor.js" /* webpackChunkName: "component---src-pages-marmor-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-pris-js": () => import("./../../../src/pages/pris.js" /* webpackChunkName: "component---src-pages-pris-js" */)
}

